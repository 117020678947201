import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import AssignmentLateOutlinedIcon from '@mui/icons-material/AssignmentLateOutlined';
import VerifiedUserOutlinedIcon from '@mui/icons-material/VerifiedUserOutlined';
import { navigator } from '@types';

const financeNav = () => {
	const root = `/finance`;
	return [
		{
			label: 'Shipper Billing',
			icon: (color: string) => <ReceiptOutlinedIcon style={{ color: color }} />,
			link: `${root}/billing/shipper`,
			enabled: true,
			activeURL: 'shipper'
		},
		{
			label: 'Carrier Billing',
			icon: (color: string) => <ReceiptLongOutlinedIcon style={{ color: color }} />,
			link: `${root}/billing/carrier`,
			enabled: true,
			activeURL: 'carrier'
		},
		{
			label: 'Issues & Claims',
			icon: (color: string) => <AssignmentLateOutlinedIcon style={{ color: color }} />,
			link: `${root}/iandc`,
			enabled: true,
			activeURL: 'iandc'
		},
		{
			label: 'Invoice Report',
			icon: (color: string) => <VerifiedUserOutlinedIcon style={{ color: color }} />,
			link: `${root}/invoice_report`,
			enabled: true,
			activeURL: 'invoice_report'
		}
	] as navigator[];
}

export default financeNav;
