import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import styles from './SideNavigation.module.scss';
import { SideNavOpen, ActiveShipper, ActiveCarrier } from '@state';
import { useRecoilState } from 'recoil';
import ArrowBackIcon from '@mui/icons-material/ArrowBackIosNew';
import { LogoIcon } from '@img/icons';
import { Text, Caption, TypeAhead, toast } from '@components';
import { useLocation, Link } from 'react-router-dom';
import shipperNav from './navigationShipper';
import carrierNav from './navigationCarrier';
import inboxNav from './navigationInbox';
import fulfilmentNav from './navigationFulfilment';
import transportNav from './navigationTransport';
import financeNav from './navigationFinance';
import configNav from './navigationConfiguration';
import {
	navigator, TypeAheadOption, ShipperSearch, CarrierSearch, TEXT_WEIGHT
} from '@types';
import { useGetShipperCarrier } from '@api';
import { debounce } from 'lodash';

export const SideNavigation = () => {
	const [sideNavOpen, setSideNavOpen] = useRecoilState(SideNavOpen);
	const [activeShipper, setActiveShipper] = useRecoilState(ActiveShipper);
	const [activeCarrier, setActiveCarrier] = useRecoilState(ActiveCarrier);
	const [search, setSearch] = useState('');
	const [searchOpts, setSearchOpts] = useState<TypeAheadOption[]>([]);
	const location = useLocation();

	const {
		loadingShippersCarriers,
		shippersCarriers,
		getShipperCarrierError,
		callGetShippersCarriers
	} = useGetShipperCarrier();

	const callSearchAPI = useCallback(debounce((type: 'carrier' | 'shipper', val: string) => {
		callGetShippersCarriers(type, val);
	}, 500), []);

	const currentPage = (label:string) => {
		const parts = location.pathname.split('/');
		return parts[1].toLocaleLowerCase() === label.toLowerCase();
	}
	const onSelectChange = (v:ChangeEvent<HTMLInputElement> | string | TypeAheadOption, fromSearch:boolean) => {
		if (fromSearch) {
			if (currentPage('shipper')) {
				setActiveShipper(((v as TypeAheadOption).value as ShipperSearch));
				localStorage.setItem('activeShipper', JSON.stringify(((v as TypeAheadOption).value as ShipperSearch)));
				setSearch(((v as TypeAheadOption).value as ShipperSearch).trade_name ?? '');
			} else {
				setActiveCarrier(((v as TypeAheadOption).value as CarrierSearch));
				localStorage.setItem('activeCarrier', JSON.stringify(((v as TypeAheadOption).value as CarrierSearch)));
				setSearch(((v as TypeAheadOption).value as CarrierSearch).trade_name ?? '');
			}
			setSearchOpts([]);
		} else {
			const val = v ? (v as ChangeEvent<HTMLInputElement>).target.value : '';
			setSearch(val);
			if (val !== '') {
				const isShipper = currentPage('shipper');
				callSearchAPI(isShipper ? 'shipper' : 'carrier', val);
			} else if (val === '') {
				if (currentPage('shipper')) {
					localStorage.removeItem('activeShipper');
					setActiveShipper(null);
				} else {
					localStorage.removeItem('activeCarrier');
					setActiveCarrier(null);
				}
			}
		}
	}
	const getNavItems = () => {
		if (currentPage('shipper')) return shipperNav(true, `${activeShipper?.shipper_id ?? ''}`) as navigator[];
		if (currentPage('carrier')) return carrierNav(true, `${activeCarrier?.carrier_id ?? ''}`) as navigator[];
		if (currentPage('inbox')) return inboxNav();
		if (currentPage('fulfilment')) return fulfilmentNav();
		if (currentPage('transport')) return transportNav();
		if (currentPage('finance')) return financeNav();
		if (currentPage('configuration')) return configNav();
		return [];
	}
	const isActive = (label:string) => {
		const parts = location.pathname.split('/');
		if (parts.length < 3) return false;
		if (currentPage('shipper') || currentPage('carrier')) {
			if (label.toLowerCase() === 'dashboard' && parts.length < 4) {
				return parts[2].toLowerCase() === label.toLowerCase();
			} else if (parts.length >= 4) {
				return parts[3].toLowerCase() === label.toLowerCase();
			} else {
				return false;
			}
		} else if (currentPage('finance')) {
			if (parts.length >= 4 && (label.toLowerCase() === 'shipper' || label.toLowerCase() === 'carrier')) {
				return parts[3] && parts[3].toLowerCase() === label.toLowerCase();
			} else if (parts.length >= 3) {
				return parts[2] && parts[2].toLowerCase() === label.toLowerCase();
			} else {
				return false;
			}
		} else {
			return parts[2].toLowerCase() === label.toLowerCase();
		}
	}

	useEffect(() => {
		if (shippersCarriers) {
			const results = shippersCarriers.searchResult[currentPage('shipper') ? 'shipper' : 'carrier'];
			const searchOpts: TypeAheadOption[] = results?.map((result: ShipperSearch | CarrierSearch) => {
				return {
					label: <div className={styles.acOption}>
						<Text weight={TEXT_WEIGHT.BOLD}>{result.trade_name}</Text>
						<Text>{result.company_name}</Text>
					</div>,
					value: result
				}
			}) ?? [];
			setSearchOpts(searchOpts);
		}
	}, [shippersCarriers]);

	useEffect(() => {
		if (getShipperCarrierError) toast.error(`An error occured searching for ${currentPage('shipper') ? 'shipper' : 'carrier'} ${search}`);
	}, [getShipperCarrierError]);

	useEffect(() => {
		if (currentPage('shipper')) {
			const activeShipper = localStorage.getItem('activeShipper');
			if (activeShipper) {
				const parsed: ShipperSearch = JSON.parse(activeShipper)
				setActiveShipper(parsed);
				setSearch(parsed.trade_name ?? '');
			}
		} else {
			const activeCarrier = localStorage.getItem('activeCarrier');
			if (activeCarrier) {
				const parsed: CarrierSearch = JSON.parse(activeCarrier)
				setActiveCarrier(parsed);
				setSearch(parsed.trade_name ?? '');
			}
		}
	}, []);

	return (
		<div data-testid={'@Ofload-SideNav'} className={`${styles.container} ${!sideNavOpen ? styles.hidden : ''}`}>
			<button data-testid={'@Ofload-SideNav-HideBtn'} className={styles.hideButton} onClick={() => { setSideNavOpen(false) }} aria-label={'Side Nav Toggle'}>
				<ArrowBackIcon style={{ fontSize: '0.9rem' }} />
			</button>
			<div className={styles.logo}>
				<LogoIcon />
			</div>
			{(currentPage('shipper') || currentPage('carrier')) && <div data-testid={'@Ofload-SearchBar'} className={styles.selectorContainer}>
				<TypeAhead
					name={'SelectShipperCarrier'}
					value={search}
					onChange={(v:ChangeEvent<HTMLInputElement>) => { onSelectChange(v, false) }}
					onSelected={(o:string | TypeAheadOption) => { onSelectChange(o, true) }}
					options={searchOpts}
					placeholder={currentPage('shipper') ? 'Select Shipper' : 'Select Carrier'}
					keepErrorSpace={false}
					onClearInput={(v) => onSelectChange(v, false)}
					showArrowIcon
					isLoadingOptions={loadingShippersCarriers}
				/>
			</div>}
			{getNavItems().map((nav:navigator, i:number) => {
				const color = isActive(nav.activeURL ?? '') ? 'var(--brand-orange-default)' : 'var(--brand-navy-default)';
				return <Link key={i} to={nav.link} className={`${styles.menuItem} ${isActive(nav.activeURL ?? '') ? styles.activeItem : ''}`}>{nav.icon(color)}<Text text={nav.label} color={color} /></Link>
			})}
			<div className={styles.tag}>
				<Caption text={'Ev2'} />
			</div>
		</div>
	);
}
