import { useGetAPI, useDeleteAPI, usePatchAPI, usePostAPI } from '@api/useAPI';
import {
	UnallocatedRows,
	TShipperBilling,
	TShipperBillingDetail,
	TBillingInvoiceStatus,
	TBillingDownload,
	ShipperCarrierSearch,
	TInvoices,
	TRecheckInvoices,
	TCarrierBillingDetail,
	TCarrierBilling,
	TIssueAndClaim,
	TClaimitems,
	TUpdateIssueAndClaim,
	TAccountables,
	TSubClaims,
	TTruckType,
	TTruckSize,
	TMarketPlaces,
	TMarketPlaceHoliday,
	TRule,
	THolidays,
	TGlobalFuelLevy,
	TShipperFuelLevy,
	TShipperList,
	TCarrierFuelLevy,
	TShipperOverviews,
	TSuperDock,
	AJAXError,
	TEOSshipment,
	TQuickStats,
	XDockAddress,
	THarmonyResponse,
	TRateCard, TMatrixFilter
} from '@types';

/*
 *	SHIPPER/CARRIER Lookup
 */

export const useGetShipperCarrier = () => {
	const {
		isLoading,
		data,
		error,
		callAPI
	} = useGetAPI({
		endpoint: '/admin/{1}/overview/{2}?search={3}',
		domain: 'Inertia'
	});

	const triggerCallAPI = (type: 'shipper' | 'carrier', search: string, isOnboard: string = 'search') => {
		callAPI({ routes: [type, isOnboard, search] });
	}

	return {
		loadingShippersCarriers: isLoading,
		shippersCarriers: data as ShipperCarrierSearch,
		getShipperCarrierError: error,
		callGetShippersCarriers: triggerCallAPI
	}
}

/*
 *	ADMIN -> FULFILMENT -> SUPERDOCK
 */

export const useCreateSuperDock = () => {
	const {
		isLoading,
		data,
		error,
		callAPI
	} = usePostAPI({ endpoint: '/api/v2/super-dock' });

	return {
		loadingSuperDock: isLoading,
		superdock: data?.data as TSuperDock,
		createSuperDockError: error as AJAXError,
		callCreateSuperdock: callAPI
	}
}

export const useGetSuperDocks = () => {
	const {
		isLoading,
		data,
		error,
		callAPI
	} = useGetAPI({ endpoint: '/api/v2/super-dock' });

	return {
		loadingSuperDock: isLoading,
		superdocks: data as {
			data: TSuperDock[];
			perPage: number;
			totalRows: number;
			currentPage: number;
		},
		getSuperDocksError: error as AJAXError,
		callGetSuperdocks: callAPI
	}
}

export const useGetSuperDock = () => {
	const {
		isLoading,
		data,
		error,
		callAPI
	} = useGetAPI({ endpoint: '/api/v2/super-dock/{1}' });

	return {
		loadingSuperDock: isLoading,
		superdock: data?.data as TSuperDock,
		getSuperDockError: error as AJAXError,
		callGetSuperdock: callAPI
	}
}

export const useUpdateSuperDock = () => {
	const {
		isLoading,
		data,
		error,
		clearError,
		callAPI
	} = usePatchAPI({ endpoint: '/api/v2/super-dock/{1}' });

	return {
		loadingUpdateSuperDock: isLoading,
		updateSuperdock: data?.data as TSuperDock,
		updateSuperDockError: error as AJAXError,
		clearError: clearError,
		callUpdateSuperdock: callAPI
	}
}

export const useDeleteSuperDocks = () => {
	const {
		isLoading,
		data,
		error,
		callAPI
	} = useDeleteAPI({ endpoint: '/api/v2/super-dock/{1}' });

	return {
		loadingDeleteSuperDock: isLoading,
		deletedSuperdocks: data as { success: boolean },
		deleteSuperDockError: error as AJAXError,
		callDeleteSuperdock: callAPI
	}
}

export const useDeleteSuperDockShipment = () => {
	const {
		isLoading,
		data,
		error,
		clearError,
		callAPI
	} = useDeleteAPI({ endpoint: '/api/v2/super-dock/{1}/original-shipment/{2}' });

	const triggerAPI = (props: { superDockID: string; shipmentID: string }) => {
		const {
			superDockID,
			shipmentID
		} = props;
		callAPI({ routes: [superDockID, shipmentID] });
	}

	return {
		loadingDeleteSDShipment: isLoading,
		deletedSDShipments: data as { type: string, message: string },
		deleteSDShipmentsError: error as AJAXError,
		clearError: clearError,
		callDeleteSDShipments: triggerAPI
	}
}

export const useGetSimilarShipments = () => {
	const {
		isLoading,
		data,
		error,
		callAPI
	} = useGetAPI({ endpoint: '/api/v2/super-dock/{1}/search-similar-shipments' });

	return {
		loadingSimilarShipments: isLoading,
		similarShipments: data?.data as TEOSshipment[],
		getSimilarShipmentsError: error as AJAXError,
		callGetSimilarShipments: callAPI
	}
}

export const useGetXDockAddress = () => {
	const {
		isLoading,
		data,
		error,
		callAPI
	} = useGetAPI({ endpoint: '/api/v2/super-dock/cross-dock-address' });

	return {
		loadingXDockAddresses: isLoading,
		XDockAddresses: data?.data as XDockAddress[],
		getXDockAddressesError: error as AJAXError,
		callGetXDockAddresses: callAPI
	}
}

export const usePostForAllocatePrice = () => {
	const {
		isLoading,
		data,
		error,
		callAPI
	} = usePostAPI({ endpoint: '/api/v2/super-dock/price' });

	return {
		loadingAllocationPrice: isLoading,
		allocationPrice: data as { 'status': string; data: TRateCard | null },
		allocationPriceError: error as AJAXError,
		callPostForAllocationPrice: callAPI
	}
}

export const useGetHarmonyResults = () => {
	const {
		isLoading,
		data,
		error,
		callAPI
	} = useGetAPI({ endpoint: '/api/admin/fulfilment/carrier/search' });

	return {
		loadingHarmonyResults: isLoading,
		harmonyResults: data as THarmonyResponse,
		getHarmonyResultsError: error as AJAXError,
		callGetHarmonyResults: callAPI
	}
}

export const useGetHarmonyRecommendationResults = () => {
	const {
		isLoading,
		data,
		error,
		clearError,
		callAPI
	} = useGetAPI({ endpoint: '/api/admin/fulfilment/carrier-recommendation/search?origin_city={1}&origin_postcode={2}&destination_city={3}&destination_postcode={4}&cargo_type={5}&cargo_subtype={6}&truck_type={7}&temperature={8}&shipment_pickup_date={9}' });

	const apiCall = (
		originCity: string,
		originPostcode: string,
		destinationCity: string,
		destinationPostcode: string,
		cargoType: string,
		cargoSubType: string,
		truckType: string,
		temperature: string,
		pickupDate: string
	) => {
		callAPI({
			routes: [
				originCity,
				originPostcode,
				destinationCity,
				destinationPostcode,
				cargoType,
				cargoSubType,
				truckType,
				temperature,
				pickupDate
			]
		})
	}

	return {
		loadingHarmonyResults: isLoading,
		harmonyResults: data as THarmonyResponse,
		getHarmonyResultsError: error as AJAXError,
		callGetHarmonyResults: apiCall,
		clearHarmonyResultsError: clearError
	}
}

export const useGetHarmonyReoptimise = () => {
	const {
		isLoading,
		data,
		error,
		clearError,
		callAPI
	} = useGetAPI({ endpoint: '/api/admin/fulfilment/carrier-recommendation/re-optimise-ltc?origin_city={1}&destination_city={2}&cargo_type={3}&cargo_subtype={4}&truck_type={5}&temperature={6}' });

	const apiCall = (
		originCity: string,
		destinationCity: string,
		cargoType: string,
		cargoSubType: string,
		truckType: string,
		temperature: string
	) => {
		callAPI({
			routes: [
				originCity,
				destinationCity,
				cargoType,
				cargoSubType,
				truckType,
				temperature
			]
		})
	}

	return {
		loadingHarmonyOptimiseResults: isLoading,
		harmonyOptimiseResults: data as THarmonyResponse,
		getHarmonyOptimiseResultsError: error as AJAXError,
		callGetHarmonyOptimiseResults: apiCall,
		clearHarmonyOptimiseResultsError: clearError
	}
}

export const usePostHarmonyFeedback = () => {
	const {
		isLoading,
		data,
		error,
		callAPI
	} = usePostAPI({ endpoint: '/api/admin/fulfilment/carrier-recommendation/feedback' });

	return {
		loadingHarmonyFeedback: isLoading,
		harmonyFeedback: data,
		postHarmonyFeedbackError: error as AJAXError,
		callPostHarmonyFeedback: callAPI
	}
}

export const useDeleteHarmonyFeedback = () => {
	const {
		isLoading,
		data,
		error,
		callAPI
	} = useDeleteAPI({ endpoint: '/api/admin/fulfilment/carrier-recommendation/delete' });

	return {
		loadingDeleteHarmonyFeedback: isLoading,
		deleteHarmonyFeedback: data,
		deleteHarmonyFeedbackError: error as AJAXError,
		callDeleteHarmonyFeedback: callAPI
	}
}

/*
 *	ADMIN -> INBOX -> QUOTES
 */

export const useGetQuotes = () => {
	const {
		isLoading,
		data,
		error,
		clearError,
		callAPI
	} = useGetAPI({ endpoint: '/api/admin/inbox/quotes' });

	return {
		loadingQuotes: isLoading,
		quotes: data,
		getQuotesError: error,
		clearGetQuotesError: clearError,
		callGetQuotes: callAPI
	}
}

export const useFilterQuotes = () => {
	const {
		isLoading,
		data,
		error,
		clearError,
		callAPI
	} = useGetAPI({ endpoint: '/api/admin/inbox/quotes?{1}' });

	const triggerAPI = (query: string) => {
		callAPI({ routes: [query] });
	}

	return {
		loadingFilterQuotes: isLoading,
		filter: data,
		getFilteredQuotesError: error,
		clearGetFilterQuotesError: clearError,
		callGetFilterQuotes: triggerAPI
	}
}

export const useDeleteQuote = () => {
	const {
		isLoading,
		data,
		error,
		clearError,
		callAPI
	} = useDeleteAPI({ endpoint: `/api/admin/inbox/quote/{1}` });

	const triggerAPI = (id: string, payload: {
		reason_id: string;
		cancellation_note: string;
	}) => {
		callAPI({ routes: [id], payload });
	}

	return {
		loadingDeleteQuote: isLoading,
		deletedQuote: data,
		getDeleteQuoteError: error,
		clearDeleteQuoteError: clearError,
		callDeleteQuote: triggerAPI
	}
}

/*
 *	ADMIN -> INBOX -> NEW SHIPPERS
 */

export const useGetNewShippers = () => {
	const {
		isLoading,
		data,
		error,
		clearError,
		callAPI
	} = useGetAPI({ endpoint: '/api/admin/inbox/shippers' });

	return {
		loadingNewShippers: isLoading,
		newShippers: data,
		getNewShippersError: error,
		clearNewShipperError: clearError,
		callGetNewShippers: callAPI
	}
}

export const useFilterNewShippers = () => {
	const {
		isLoading,
		data,
		error,
		clearError,
		callAPI
	} = useGetAPI({ endpoint: '/api/admin/inbox/shippers?{1}' });

	const triggerAPI = (query: string) => {
		callAPI({ routes: [query] });
	}

	return {
		loadingFilterNewShippers: isLoading,
		filter: data,
		getFilteredNewShippersError: error,
		clearFilterShipperError: clearError,
		callGetFilterNewShippers: triggerAPI
	}
}

export const useActivateShipper = () => {
	const {
		isLoading,
		data,
		error,
		clearError,
		callAPI
	} = usePatchAPI({ endpoint: '/api/admin/shipper/{1}/shipper-activate' });

	const triggerAPI = (id: string, payload: any) => {
		callAPI({ routes: [id], payload });
	}

	return {
		loadingActivateShipper: isLoading,
		activateShipper: data,
		getActivateShipperError: error,
		clearActivateShipperError: clearError,
		callActivateShipper: triggerAPI
	}
}

export const useRejectShipper = () => {
	const {
		isLoading,
		data,
		error,
		clearError,
		callAPI
	} = usePatchAPI({ endpoint: '/api/admin/shipper/reject-shipper' });

	const triggerAPI = (payload: any) => {
		callAPI({ payload });
	}

	return {
		loadingRejectShipper: isLoading,
		rejectShipper: data,
		getRejectShipperError: error,
		clearRejectShipperError: clearError,
		callRejectShipper: triggerAPI
	}
}

export const useDeleteShipper = () => {
	const {
		isLoading,
		data,
		error,
		clearError,
		callAPI
	} = usePatchAPI({ endpoint: '/api/admin/shipper/delete-shipper' });

	const triggerAPI = (payload: any) => {
		callAPI({ payload });
	}

	return {
		loadingDeleteShipper: isLoading,
		deleteShipper: data,
		getDeleteShipperError: error,
		clearDeleteShipperError: clearError,
		callDeleteShipper: triggerAPI
	}
}

/*
 *	ADMIN -> INBOX -> NEW CARRIERS
 */

export const useGetNewCarriers = () => {
	const {
		isLoading,
		data,
		error,
		clearError,
		callAPI
	} = useGetAPI({ endpoint: '/api/admin/inbox/carriers' });

	return {
		loadingNewCarriers: isLoading,
		newCarriers: data,
		getNewCarriersError: error,
		clearNewCarrierError: clearError,
		callGetNewCarriers: callAPI
	}
}

export const useFilterNewCarriers = () => {
	const {
		isLoading,
		data,
		error,
		clearError,
		callAPI
	} = useGetAPI({ endpoint: '/api/admin/inbox/carriers?{1}' });

	const triggerAPI = (query: string) => {
		callAPI({ routes: [query] });
	}

	return {
		loadingFilterNewCarriers: isLoading,
		filter: data,
		getFilteredNewCarriersError: error,
		clearFilterdCarrierError: clearError,
		callGetFilterNewCarriers: triggerAPI
	}
}

export const useActivateCarrier = () => {
	const {
		isLoading,
		data,
		error,
		clearError,
		callAPI
	} = usePatchAPI({ endpoint: '/api/admin/carrier/{1}/activate' });

	const triggerAPI = (id: string, payload: any) => {
		callAPI({ routes: [id], payload });
	}

	return {
		loadingActivateCarrier: isLoading,
		activateCarrier: data,
		getActivateCarrierError: error,
		clearActivateCarrierError: clearError,
		callActivateCarrier: triggerAPI
	}
}

export const useRejectCarrier = () => {
	const {
		isLoading,
		data,
		error,
		clearError,
		callAPI
	} = usePatchAPI({ endpoint: '/api/admin/carrier/reject-carrier' });

	const triggerAPI = (payload: any) => {
		callAPI({ payload });
	}

	return {
		loadingRejectCarrier: isLoading,
		rejectCarrier: data,
		getRejectCarrierError: error,
		clearRejectCarrierError: clearError,
		callRejectCarrier: triggerAPI
	}
}

export const useDeleteCarrier = () => {
	const {
		isLoading,
		data,
		error,
		clearError,
		callAPI
	} = usePatchAPI({ endpoint: '/api/admin/carrier/delete-carrier' });

	const triggerAPI = (payload: any) => {
		callAPI({ payload });
	}

	return {
		loadingDeleteCarrier: isLoading,
		deleteCarrier: data,
		getDeleteCarrierError: error,
		clearDeleteCarrierError: clearError,
		callDeleteCarrier: triggerAPI
	}
}

/*
 *	ADMIN -> SHIPPER -> DASHBOARD
 */
export const useGetShipperOverview = () => {
	const {
		isLoading,
		data,
		error,
		callAPI
	} = useGetAPI({ endpoint: '/api/admin/shipper/overview?{1}' });

	const triggerAPI = (query:string|null = null) => {
		const apiParams = query ? { routes: [query] } : {};
		callAPI(apiParams);
	}

	return {
		loadingOverviews: isLoading,
		overviews: data as TShipperOverviews,
		getOverviewsError: error,
		callGetOverview: triggerAPI
	}
}

/*
 *	ADMIN -> FINANCE -> SHIPPER BILLING
 */
export const useGetShipperBilling = () => {
	const {
		isLoading,
		data,
		error,
		callAPI
	} = useGetAPI({ endpoint: '/api/admin/finance/shipper/billing' });

	return {
		loadingShipperBilling: isLoading,
		shipperBilling: data as TShipperBilling,
		getShipperBillingError: error,
		callGetShipperBilling: callAPI
	}
}
export const useFilterShipperBilling = () => {
	const {
		isLoading,
		data,
		error,
		callAPI
	} = useGetAPI({ endpoint: '/api/admin/finance/shipper/billing?{1}' });

	const triggerAPI = (query: string) => {
		callAPI({ routes: [query] });
	}

	return {
		loadingFilterShipperBilling: isLoading,
		filter: data as TShipperBilling,
		getFilteredShipperBillingError: error,
		callGetFilterShipperBilling: triggerAPI
	}
}
export const useDownloadShipperBilling = () => {
	const {
		data,
		callAPI
	} = useGetAPI({ endpoint: '/api/admin/finance/shipper/billing/{1}/download' });

	const triggerAPI = (query: string) => {
		callAPI({ routes: [query] });
	}

	return {
		downloadShipperBilling: data as TBillingDownload,
		callGetDownloadShipperBilling: triggerAPI
	}
}

export const useGetShipperBillingDetail = () => {
	const {
		isLoading,
		data,
		callAPI
	} = useGetAPI({ endpoint: '/api/admin/finance/shipper/billing/{1}/detail' });

	const triggerAPI = (query: string) => {
		callAPI({ routes: [query] });
	}

	return {
		loadingShipperBillingDetail: isLoading,
		shipperBillingDetail: data as TShipperBillingDetail,
		callGetShipperBillingDetail: triggerAPI
	}
}
export const useFilterShipperBillingLineItems = () => {
	const {
		isLoading,
		data,
		callAPI
	} = useGetAPI({ endpoint: '/api/admin/finance/shipper/billing/{1}/detail?{1}' });

	const triggerAPI = (query: string) => {
		callAPI({ routes: [query] });
	}

	return {
		loadingShipperBillingLineItems: isLoading,
		filter: data as TShipperBillingDetail,
		callGetFilterShipperBillingLineItems: triggerAPI
	}
}
/*
 *	ADMIN -> FINANCE -> CARRIER BILLING
 */
export const useGetCarrierBilling = () => {
	const {
		isLoading,
		data,
		error,
		callAPI
	} = useGetAPI({ endpoint: '/api/admin/finance/carrier/billing' });

	return {
		loadingCarrierBilling: isLoading,
		carrierBilling: data as TCarrierBilling,
		getCarrierBillingError: error,
		callGetCarrierBilling: callAPI
	}
}
export const useFilterCarrierBilling = () => {
	const {
		isLoading,
		data,
		error,
		callAPI
	} = useGetAPI({ endpoint: '/api/admin/finance/carrier/billing?{1}' });

	const triggerAPI = (query: string) => {
		callAPI({ routes: [query] });
	}

	return {
		loadingFilterCarrierBilling: isLoading,
		filter: data as TCarrierBilling,
		getFilteredCarrierBillingError: error,
		callGetFilterCarrierBilling: triggerAPI
	}
}
export const useDownloadCarrierBilling = () => {
	const {
		data,
		callAPI
	} = useGetAPI({ endpoint: '/api/admin/finance/carrier/billing/{1}/download' });

	const triggerAPI = (query: string) => {
		callAPI({ routes: [query] });
	}

	return {
		downloadCarrierBilling: data as TBillingDownload,
		callGetDownloadCarrierBilling: triggerAPI
	}
}

export const useGetCarrierBillingDetail = () => {
	const {
		isLoading,
		data,
		callAPI
	} = useGetAPI({ endpoint: '/api/admin/finance/shipper/billing/{1}/detail' });

	const triggerAPI = (query: string) => {
		callAPI({ routes: [query] });
	}

	return {
		loadingCarrierBillingDetail: isLoading,
		carrierBillingDetail: data as TCarrierBillingDetail,
		callGetCarrierBillingDetail: triggerAPI
	}
}
export const useFilterCarrierBillingLineItems = () => {
	const {
		isLoading,
		data,
		callAPI
	} = useGetAPI({ endpoint: '/api/admin/finance/carrier/billing/{1}/detail?{1}' });

	const triggerAPI = (query: string) => {
		callAPI({ routes: [query] });
	}

	return {
		loadingCarrierBillingLineItems: isLoading,
		filter: data as TCarrierBillingDetail,
		callGetFilterCarrierBillingLineItems: triggerAPI
	}
}
export const usePatchInvoiceStatus = () => {
	const {
		isLoading,
		data,
		error,
		callAPI
	} = usePatchAPI({ endpoint: '/api/admin/finance/invoice/{1}/status' });

	const triggerAPI = (id: string, payload: any) => {
		callAPI({ routes: [id], payload });
	}

	return {
		loadingInvoiceStatus: isLoading,
		getInvoiceStatus: data as TBillingInvoiceStatus,
		getInvoiceStatusError: error,
		callUpdateInvoiceStatus: triggerAPI
	}
}

/*
 * ADMIN -> FULFILMENT -> UNALLOCATED
 */

export const useGetQuickStats = () => {
	const {
		isLoading,
		data,
		error,
		callAPI
	} = useGetAPI({ endpoint: '/api/admin/fulfilment/quick-stats' });

	return {
		loadingQuickStats: isLoading,
		quickStats: data?.data as TQuickStats[],
		quickStatsError: error,
		callGetQuickStats: callAPI
	}
}

export const useGetUnallocated = () => {
	const {
		isLoading,
		data,
		error,
		clearError,
		callAPI
	} = useGetAPI({ endpoint: '/api/admin/fulfilment/unallocated' });

	return {
		loadingUnallocated: isLoading,
		unallocated: data as {
			rows: UnallocatedRows[];
			perPage: number;
			totalRows: number;
			currentPage: number;
		},
		unallocatedError: error,
		clearUnallocatedError: clearError,
		callGetUnallocated: callAPI
	}
}

export const useGetMatrixFilter = () => {
	const {
		isLoading,
		data,
		error,
		clearError,
		callAPI
	} = useGetAPI({ endpoint: '/api/admin/operations-matrix' });

	return {
		loadingMatrix: isLoading,
		matrixs: data as TMatrixFilter[],
		matrixError: error,
		clearMatrixError: clearError,
		callGetMatrix: callAPI
	}
}

export const useAssignTo = () => {
	const {
		isLoading,
		data,
		error,
		clearError,
		callAPI
	} = usePostAPI({ endpoint: '/api/admin/fulfilment/shipment/{1}/assign' });

	const triggerAPI = (shipment_id: number, payload: any) => {
		callAPI({ routes: [`${shipment_id}`], payload });
	}

	return {
		loadingAssignTo: isLoading,
		assignedTo: data,
		assignedToError: error,
		clearAssignedToError: clearError,
		callAssignTo: triggerAPI
	}
}

export const useUnassignTo = () => {
	const {
		isLoading,
		data,
		error,
		clearError,
		callAPI
	} = usePostAPI({ endpoint: '/api/admin/fulfilment/shipment/{1}/unassign' });

	const triggerAPI = (shipment_id: number) => {
		callAPI({ routes: [`${shipment_id}`] });
	}

	return {
		loadingUnassignTo: isLoading,
		unassignedTo: data,
		unassignedToError: error,
		clearUnassignedToError: clearError,
		callUnassignTo: triggerAPI
	}
}

export const useUpdateOrderNumber = () => {
	const {
		data,
		error,
		clearError,
		callAPI
	} = usePostAPI({ endpoint: '/api/admin/shipment/update-order-number' });

	return {
		updateOrderNumber: data,
		updateOrderNumberError: error as AJAXError,
		clearError,
		callUpdateOrderNumber: callAPI
	}
}

/*
 *	ADMIN -> FINANCE -> ISSUES AND CLAIMS
 */

export const useGetIssueAndClaims = () => {
	const {
		isLoading,
		data,
		error,
		callAPI
	} = useGetAPI({ endpoint: '/api/admin/finance/claimLines' });

	return {
		loadingIssueAndClaims: isLoading,
		issueAndClaims: data?.rows as TIssueAndClaim[],
		getIssueAndClaimsError: error,
		callIssueAndClaims: callAPI
	}
}

export const useUpdateClaimLines = () => {
	const {
		isLoading,
		data,
		error,
		callAPI
	} = usePatchAPI({ endpoint: '/api/admin/finance/claims/{1}/claimLines/{2}' });

	const triggerAPI = (claim: number, claimLine: number, payload: any) => {
		callAPI({ routes: [`${claim}`, 	`${claimLine}`], payload });
	}
	return {
		loadingUpdateClaimLines: isLoading,
		updateClaimLines: data as TUpdateIssueAndClaim,
		getUpdateClaimLinesError: error,
		callUpdateClaimLines: triggerAPI
	}
}

export const useGetAccountables = () => {
	const {
		isLoading,
		data,
		error,
		callAPI
	} = useGetAPI({ endpoint: '/api/admin/transport/accountables' });

	return {
		loadingAccountables: isLoading,
		accountables: data as TAccountables[],
		getAccountablesError: error,
		callAccountables: callAPI
	}
}

export const useGetClaimSubItems = () => {
	const {
		isLoading,
		data,
		error,
		callAPI
	} = useGetAPI({ endpoint: '/api/admin/transport/claim-sub-items?claim_master_id={1}' });

	const triggerAPI = (claim_master_id: number) => {
		callAPI({ routes: [`${claim_master_id}`] });
	}
	return {
		loadingClaimSubItems: isLoading,
		claimSubItems: data as TSubClaims[],
		getClaimSubItemsError: error,
		callClaimSubItems: triggerAPI
	}
}

export const useGetClaimItems = () => {
	const {
		isLoading,
		data,
		error,
		callAPI
	} = useGetAPI({ endpoint: '/api/admin/transport/claim-items' });

	return {
		loadingClaimItems: isLoading,
		claimItems: data as TClaimitems[],
		getClaimItemsError: error,
		callClaimItems: callAPI
	}
}


export const useDeleteClaimItem = () => {
	const {
		isLoading,
		data,
		error,
		callAPI
	} = useDeleteAPI({ endpoint: '/api/admin/finance/claims/{1}/claimLines/{2}' });

	const triggerAPI = (claim:number, claimLine:number) => {
		callAPI({ routes: [`${claim}`, 	`${claimLine}`] });
	}

	return {
		loadingDeleteClaimItem: isLoading,
		deletedClaimItem: data as TUpdateIssueAndClaim,
		getDeleteClaimItemError: error,
		callDeleteClaimItem: triggerAPI
	}
}

export const useFilterIssueAndClaims = () => {
	const {
		isLoading,
		data,
		error,
		callAPI
	} = useGetAPI({ endpoint: '/api/admin/finance/claimLines?{1}' });

	const triggerAPI = (query: string) => {
		callAPI({ routes: [query] });
	}

	return {
		loadingFilterIssueAndClaims: isLoading,
		filter: data?.rows as TIssueAndClaim[],
		getFilteredIssueAndClaimsError: error,
		callGetFilterIssueAndClaims: triggerAPI
	}
}

/*
 *	ADMIN -> FINANCE -> INVOICE REPORT
 */

export const useGetInvoices = () => {
	const {
		isLoading,
		data,
		error,
		callAPI
	} = useGetAPI({ endpoint: '/api/admin/finance/invoice-audit' });

	return {
		loadingInvoices: isLoading,
		invoices: data as TInvoices,
		getInvoicesError: error,
		callGetInvoices: callAPI
	}
}

export const useRecheckInvoices = () => {
	const {
		isLoading,
		data,
		error,
		callAPI
	} = usePatchAPI({ endpoint: '/api/admin/finance/invoice-audit/{1}/recheck' });

	const triggerAPI = (id: number) => {
		callAPI({ routes: [`${id}`] });
	}

	return {
		loadingReCheckInvoices: isLoading,
		reCheckInvoices: data as TRecheckInvoices,
		getReCheckInvoicesError: error,
		callRecheckInvoices: triggerAPI
	}
}


export const useResolveInvoices = () => {
	const {
		isLoading,
		data,
		error,
		callAPI
	} = usePatchAPI({ endpoint: '/api/admin/finance/invoice-audit/{1}/resolve' });

	const triggerAPI = (id: number) => {
		callAPI({ routes: [`${id}`] });
	}

	return {
		loadingResolveInvoices: isLoading,
		resolveInvoices: data as TRecheckInvoices,
		getResolveInvoicesError: error,
		callResolveInvoices: triggerAPI
	}
}
export const useFilterInvoiceReport = () => {
	const {
		isLoading,
		data,
		error,
		callAPI
	} = useGetAPI({ endpoint: '/api/admin/finance/invoice-audit?{1}' });

	const triggerAPI = (query: string) => {
		callAPI({ routes: [query] });
	}

	return {
		loadingFilterInvoiceReport: isLoading,
		filter: data?.rows as TInvoices,
		getFilteredInvoiceReportError: error,
		callGetFilterInvoiceReport: triggerAPI
	}
}

/*
 *	ADMIN -> CONFIGURATION -> TRUCK TYPE
 */
export const useGetTruckTypes = () => {
	const {
		isLoading,
		data,
		error,
		callAPI
	} = useGetAPI({ endpoint: '/api/admin/configuration/trucktypes' });

	return {
		loadingTruckType: isLoading,
		truckType: data?.trucktype as TTruckType[],
		getTruckTypeError: error,
		callGetTruckType: callAPI
	}
}

export const useGetTruckSize = () => {
	const {
		isLoading,
		data,
		error,
		callAPI
	} = useGetAPI({ endpoint: '/api/admin/configuration/trucktypes' });

	return {
		loadingTruckSize: isLoading,
		truckSize: data?.trucksize as TTruckSize[],
		getTruckSizeError: error,
		callGetTruckSize: callAPI
	}
}

/*
 *	ADMIN -> CONFIGURATION -> MARKETPLACE
 */

export const useMarketPlace = () => {
	const {
		isLoading,
		data,
		error,
		callAPI
	} = useGetAPI({ endpoint: '/api/admin/configuration/marketplace' });

	return {
		loadingMarketPlace: isLoading,
		marketPlaces: data as TMarketPlaces,
		getMarketPlaceError: error,
		callGetMarketPlaces: callAPI
	}
}

export const useUpdateMarketPlace = () => {
	const {
		isLoading,
		data,
		error,
		callAPI
	} = usePostAPI({ endpoint: '/api/admin/marketplaceRuleUpdate' });
	const triggerAPI = (payload: TRule) => {
		callAPI({ payload });
	}
	return {
		loadingUpdateMarketPlace: isLoading,
		updateMarketPlaces: data as TMarketPlaceHoliday,
		getUpdateMarketPlaceError: error,
		callUpdateMarketPlaces: triggerAPI
	}
}

export const useCreateMarketPlaceHoliday = () => {
	const {
		isLoading,
		data,
		error,
		callAPI
	} = usePostAPI({ endpoint: '/api/admin/marketplaceHolidayCreate' });
	const triggerAPI = (payload: THolidays) => {
		callAPI({ payload });
	}
	return {
		loadingCreateMarketPlaceHoliday: isLoading,
		createMarketPlaceHoliday: data as TMarketPlaceHoliday,
		getCreateMarketPlaceHolidayError: error,
		callCreateMarketPlaceHoliday: triggerAPI
	}
}

export const useUpdateMarketPlaceHoliday = () => {
	const {
		isLoading,
		data,
		error,
		callAPI
	} = usePostAPI({ endpoint: '/api/admin/marketplaceHolidayUpdate' });
	const triggerAPI = (payload: THolidays) => {
		callAPI({ payload });
	}
	return {
		loadingUpdateMarketPlaceHoliday: isLoading,
		updateMarketPlaceHoliday: data as TMarketPlaceHoliday,
		getUpdateMarketPlaceHolidayError: error,
		callUpdateMarketPlaceHoliday: triggerAPI
	}
}

export const useDeleteMarketPlaceHoliday = () => {
	const {
		isLoading,
		data,
		error,
		callAPI
	} = usePostAPI({ endpoint: '/api/admin/marketplaceHolidayDelete' });
	const triggerAPI = (payload: THolidays) => {
		callAPI({ payload });
	}
	return {
		loadingDeleteMarketPlaceHoliday: isLoading,
		deleteMarketPlaceHoliday: data as TMarketPlaceHoliday,
		getDeleteMarketPlaceHolidayError: error,
		callDeleteMarketPlaceHoliday: triggerAPI
	}
}
export const useGetFuelLevy = () => {
	const {
		isLoading,
		data,
		error,
		callAPI
	} = useGetAPI({ endpoint: '/api/admin/configuration/fuelLevy' });
	return {
		loadingFuelLevy: isLoading,
		globalFuelLevy: {
			data: data?.globalLevy?.rows as TGlobalFuelLevy[],
			currentPage: data?.globalLevy?.current_page as number,
			perPage: data?.globalLevy?.per_page as number,
			total: data?.globalLevy?.total as number,
		},
		shipperLevy: {
			data: data?.shipperLevy?.rows as TShipperFuelLevy[],
			currentPage: data?.shipperLevy?.current_page as number,
			perPage: data?.shipperLevy?.per_page as number,
			total: data?.shipperLevy?.total as number,
		},
		carrierLevy: {
			data: data?.carrierLevy?.rows as TCarrierFuelLevy[],
			currentPage: data?.carrierLevy?.current_page as number,
			perPage: data?.carrierLevy?.per_page as number,
			total: data?.carrierLevy?.total as number,
		},
		getFuelLevyError: error,
		callGetFuelLevy: callAPI
	}
}
export const useGetFilterFuelLevy = () => {
	const {
		isLoading,
		data,
		error,
		callAPI
	} = useGetAPI({ endpoint: '/api/admin/configuration/fuelLevy?{1}' });
	const triggerAPI = (query: string) => {
		callAPI({ routes: [query] });
	}
	return {
		loadingFilterFuelLevy: isLoading,
		globalFilterFuelLevy: {
			data: data?.globalLevy?.rows as TGlobalFuelLevy[],
			currentPage: data?.globalLevy?.current_page as number,
			perPage: data?.globalLevy?.per_page as number,
			total: data?.globalLevy?.total as number,
		},
		filterShipperLevy: {
			data: data?.shipperLevy?.rows as TShipperFuelLevy[],
			currentPage: data?.shipperLevy?.current_page as number,
			perPage: data?.shipperLevy?.per_page as number,
			total: data?.shipperLevy?.total as number,
		},
		filterCarrierLevy: {
			data: data?.carrierLevy?.rows as TCarrierFuelLevy[],
			currentPage: data?.carrierLevy?.current_page as number,
			perPage: data?.carrierLevy?.per_page as number,
			total: data?.carrierLevy?.total as number,
		},
		getFilterFuelLevyError: error,
		callGetFilterFuelLevy: triggerAPI
	}
}
export const useUpdateFuelLevy = () => {
	const {
		isLoading,
		data,
		error,
		callAPI
	} = usePostAPI({ endpoint: '/api/admin/configuration/fuelLevy' });
	const triggerAPI = (payload: { accept_auto_change?: boolean, shipper_id?: number, carrier_id?: number, value?: string, start_at?:string }) => {
		callAPI({ payload });
	}
	return {
		loadingUpdateFuelLevy: isLoading,
		updateShipperLevy: data as { type: string, message: string },
		getUpdateFuelLevyError: error,
		callUpdateFuelLevy: triggerAPI
	}
}
export const useGetShipperList = () => {
	const {
		isLoading,
		data,
		error,
		callAPI
	} = useGetAPI({ endpoint: '/api/admin/shippers' });
	return {
		loadingShipperList: isLoading,
		shipperList: data?.data as TShipperList,
		getShipperListError: error,
		callGetShipperList: callAPI
	}
}

export const useGetCarrierList = () => {
	const {
		isLoading,
		data,
		error,
		callAPI
	} = useGetAPI({ endpoint: '/api/admin/carriers' });
	return {
		loadingCarrierList: isLoading,
		carrierList: data?.data as TShipperList,
		getCarrierListError: error,
		callGetCarrierList: callAPI
	}
}
