import DashboardIcon from '@mui/icons-material/Dashboard';
import TodayOutlinedIcon from '@mui/icons-material/TodayOutlined';
import ListAltIcon from '@mui/icons-material/ListAlt';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import RateReviewOutlinedIcon from '@mui/icons-material/RateReviewOutlined';
import PriceChangeOutlinedIcon from '@mui/icons-material/PriceChangeOutlined';
import RequestQuoteOutlinedIcon from '@mui/icons-material/RequestQuoteOutlined';
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import TrendingUpOutlinedIcon from '@mui/icons-material/TrendingUpOutlined';
import StoreOutlinedIcon from '@mui/icons-material/StoreOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { navigator } from '@types';

const shipperNav = (enabled:boolean, sID?:string) => {
	const root = `/shipper/${sID}`;
	const shipperMenuItems = [
		{
			label: 'Today',
			icon: (color:string) => <TodayOutlinedIcon style={{ color: color }} />,
			link: `${root}/today`,
			enabled,
			activeURL: 'today'
		},
		{
			label: 'Shipments',
			icon: (color:string) => <ListAltIcon style={{ color: color }} />,
			link: `${root}/shipments`,
			enabled,
			activeURL: 'shipments'
		},
		{
			label: 'Upload Shipments',
			icon: (color:string) => <UploadFileIcon style={{ color: color }} />,
			link: `${root}/upload_shipments`,
			enabled,
			activeURL: 'upload_shipments'
		},
		{
			label: 'Book a Shipment',
			icon: (color:string) => <ControlPointIcon style={{ color: color }} />,
			link: `${root}/booking`,
			enabled,
			activeURL: 'booking'
		},
		{
			label: 'Requested Quotes',
			icon: (color:string) => <RateReviewOutlinedIcon style={{ color: color }} />,
			link: `${root}/requested_quotes`,
			enabled,
			activeURL: 'requested_quotes'
		},
		{
			label: 'Rate Cards',
			icon: (color:string) => <PriceChangeOutlinedIcon style={{ color: color }} />,
			link: `${root}/rate_cards`,
			enabled,
			activeURL: 'rate_cards'
		},
		{
			label: 'Quotes',
			icon: (color:string) => <RequestQuoteOutlinedIcon style={{ color: color }} />,
			link: `${root}/quotes`,
			enabled,
			activeURL: 'quotes'
		},
		{
			label: 'Invoices',
			icon: (color:string) => <ReceiptOutlinedIcon style={{ color: color }} />,
			link: `${root}/invoices`,
			enabled,
			activeURL: 'invoices'
		},
		{
			label: 'Analytics',
			icon: (color:string) => <TrendingUpOutlinedIcon style={{ color: color }} />,
			link: `${root}/analytics`,
			enabled,
			activeURL: 'analytics'
		},
		{
			label: 'Company',
			icon: (color:string) => <StoreOutlinedIcon style={{ color: color }} />,
			link: `${root}/company`,
			enabled: true,
			activeURL: 'company'
		},
		{
			label: 'Settings',
			icon: (color:string) => <SettingsOutlinedIcon style={{ color: color }} />,
			link: `${root}/settings`,
			enabled: true,
			activeURL: 'settings'
		}
	] as navigator[];
	const defaultMenuItems = [
		{
			label: 'Dashboard',
			icon: (color:string) => <DashboardIcon style={{ color: color }} />,
			link: `${root}/dashboard`,
			enabled: true,
			activeURL: 'dashboard'
		}
	] as navigator[];

	return sID ? [...defaultMenuItems, ...shipperMenuItems] as navigator[] : defaultMenuItems as navigator[];
}

export default shipperNav;
