import { useEffect } from 'react';
import styles from './MainLayout.module.scss';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import { TopNavigation, SideNavigation } from '@components';
import { useRecoilValue, useSetRecoilState, useRecoilState } from 'recoil';
import { useGetMe } from '@api';
import {
	SideNavOpen,
	IsAdmin,
	JWT,
	UserName,
	CompanyName,
	UserMenuOpen
} from '@state';

type LayoutProps = {
	requireAdmin?: boolean;
	initComplete?: boolean;
}

const MainLayout = (props:LayoutProps) => {
	const { requireAdmin = false, initComplete = false } = props;
	const navigate = useNavigate();
	const location = useLocation();
	const setUserName = useSetRecoilState(UserName);
	const setCompanyName = useSetRecoilState(CompanyName);
	const sideNavOpen = useRecoilValue(SideNavOpen);
	const [userMenuOpen, setUserMenuOpen] = useRecoilState(UserMenuOpen);
	const isAdmin = useRecoilValue(IsAdmin);
	const jwt = useRecoilValue(JWT);
	const {
		me,
		callGetMe
	} = useGetMe();

	useEffect(() => {
		if (requireAdmin && initComplete && !isAdmin) navigate(`/login`, { replace: true });
	}, [requireAdmin]);

	useEffect(() => {
		if (jwt && initComplete) {
			callGetMe();
		} else if (!jwt && initComplete) {
			navigate(`/login?backto=${location.pathname}${location.search}`, { replace: true });
		}
	}, [jwt]);

	useEffect(() => {
		if(me) {
			setUserName(`${me.data.team.first_name} ${me.data.team.last_name}`);
			setCompanyName(me.data.company.company_name);
		}
	}, [me]);

	useEffect(() => {
		callGetMe();
	}, []);

	return (
		<div data-testid={'@Ofload-MainLayout'} className={styles.mainLayout}>
			<div className={`${styles.blurredbox} ${userMenuOpen ? styles.blurred : ''}`} onClick={() => { setUserMenuOpen(!userMenuOpen) }} />
			<SideNavigation />
			<TopNavigation />
			<div data-testid={'@Ofload-MainLayout-Content'} className={`${styles.content} ${sideNavOpen ? styles.padded : ''}`}>
				<Outlet />
			</div>
		</div>
	);
}

export default MainLayout;
